import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"
import { Row, Col } from "react-bootstrap"

const SolutionCard = ({
  solutionImg,
  solutionTitle,
  solutionDescription,
  solutionButtonAction,
}) => (
  <Row className={"mt-4"}>
    <Col
      className={"col-lg-2 col-3"}
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div className="imageCircle">
        <img className="imageVector" src={solutionImg} />
      </div>
    </Col>
    <Col className={"col-lg-8 col-8"}>
      <button onClick={solutionButtonAction} className="title-button">
        <h4>{solutionTitle}</h4>
      </button>
      <p>{solutionDescription}</p>
    </Col>
  </Row>
)

SolutionCard.propTypes = {
  solutionImg: PropTypes.any,
  solutionTitle: PropTypes.string,
  solutionDescription: PropTypes.string,
  solutionButtonAction: PropTypes.func,
}

SolutionCard.defaultProps = {
  solutionTitle: ``,
  solutionDescription: ``,
}

export default SolutionCard
