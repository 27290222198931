import React from "react"
import PropTypes from "prop-types"

import "./styles.scss"

const InfoCard = ({ cardTitle, cardDescription, customStyle }) => (
  <div className={customStyle}>
    <div>
      <h2 className="info-card-title">{cardTitle}</h2>
      <p>{cardDescription}</p>
    </div>
  </div>
)

InfoCard.propTypes = {
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string,
  customStyle: PropTypes.string,
}

InfoCard.defaultProps = {
  cardTitle: ``,
  cardDescription: ``,
  customStyle: ``,
}

export default InfoCard
